<template lang="pug">
#LoginPage
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    header(v-show='!loading')
        .header-area.header-transparrent
            .main-header.header-sticky
                .container
                    .row.align-items-center.justify-content-md-between
                        .col-xl-2.col-md-2
                        .col-xl-8.col-lg-8.col-md-8.d-md-flex.align-items-center.justify-content-md-end
                            .header-right-btn.f-right.d-none.d-lg-block.ml-lg-4
                                .btn.header-btn(@click='tryFree = true') {{$t('LOGIN.TRY')}}
    #login
        .container
            .row
                .col-12.col-md-6
                    h3.text-left.mb-5 {{$t('LOGIN.DESCRIPTION')}}
                    img(src='../../static/loginPage/images/crm-pic.gif', alt='' width='100%')
                .col-12.col-md-6
                    .login-box.my-5
                        h3.mb-4 {{$t('LOGIN.LOGIN')}}
                        form
                            .input-group.mb-6
                                v-text-field(v-model='username' :placeholder=`$t('LOGIN.ACCOUNT')` tabindex='1' outlined dense :autofocus='true')
                            .input-group.mb-6
                                v-text-field(v-model='password' :placeholder=`$t('LOGIN.PWD')` tabindex='2' outlined dense :append-icon='iconType' :type="passwordType" @click:append="showPwd")
                            .input-group.mb-6(:class='displayNone ? "d-none" : ""')
                                v-text-field(v-model='tenant' :placeholder=`$t('LOGIN.TENANT')` tabindex='3' outlined dense)
                            .input-group.mb-6(:class='!displayLoginVali ? "d-none" : ""')
                                v-text-field(v-model='loginValiCode' :placeholder=`$t('LOGIN.LOGIN_VALI_CODE')` tabindex='4' outlined dense :append-icon='loginValiCodeIcon' :suffix=`$t('LOGIN.GET_VALI_CODE')` @click:append="sendLoginValiCode")
                            input.btn.hero-btn.w-100(v-if="displayUpDayLogin && !displayUpGPTLogin", type="submit", :value=`$t('LOGIN.LOGIN_2')` tabindex='5' @click='handleLogin($event, true)')
                            input.btn.hero-btn.w-100(v-if="!displayUpDayLogin && !displayUpGPTLogin", type="submit", :value=`$t('LOGIN.LOGIN_UPCHAT')` tabindex='6' @click='handleLogin($event, false)')
                            input.btn.hero-btn.w-100(v-if="displayUpGPTLogin", type="submit", :value=`$t('LOGIN.LOGIN_UPGPT')` tabindex='6' @click='handleLogin($event, false)')
                            .d-block.text-center.my-4.pointer(@click='forgetPassword = true') {{$t('LOGIN.PASSWORD')}}
    v-dialog(v-model='forgetPassword' max-width='500')
        forgetPassword-dialog(@emitForgetPasswordDialog='onEmitForgetPasswordDialog' @emitForgetPasswordSuccess='onEmitForgetPasswordSuccess')
    v-dialog(v-model='tryFree' max-width='500')
        invitation-dialog(@emitInvitationDialog='onEmitInvitationDialog' @emitThankYouDialog='onEmitThankYouDialog')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog()')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>
<script>
import { mapActions } from 'vuex';
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import messageDialog from "@/components/Dialog/messageDialog";
import loadingOverlay from "@/components/Common/loadingOverlay";
import invitationDialog from "@/components/Dialog/User/invitationDialog";
import forgetPasswordDialog from "@/components/Dialog/User/forgetPasswordDialog";
import passData from "@/services/passData.js";
import i18n from '/common/plugins/vue-i18n.js' 
import LoginValidationService from '../services/LoginValidationService';

export default {
  name: "Login",
  components: {
        errorDialog,
        successDialog,
        messageDialog,
        loadingOverlay,
        invitationDialog,
        forgetPasswordDialog,
        passData
  },
  data() {
    return {
        loading: false,
        username: '',
        password: '',
        tenant: '',
        loginValiCode: '',
        forget_tenant: '',
        forget_email: '',
        nextPath: '/',
        forgetPassword: false,
        successDialog: false,
        errorDialog: false,
        errorMessage: '',
        messageDialog: false,
        message: '',
        tryFree: false,
        passwordType: "password",
        iconType:"fa fa-eye-slash",
        loginValiCodeIcon: "fa fa-key",
        changePassword:false,
        nonetenant:false,
        noneusername:false,
        displayNone: false,
        displayLoginVali: false,
        displayUpDayLogin: true,
        displayUpGPTLogin: false,
        isUpchatUser: window.localStorage.getItem('isUpchatUser'),
        upsidLoading:true,
    };
  },
    mounted() {
        this.updateAfterLoginNextPath();
    },
    async created(){
        window.addEventListener('keyup', this.close)

        // test if user have tenant in url
        let nowPath = this.$router.currentRoute.fullPath;
        // let test = 'tenant%3D';
        let test = 'login?tenant=';
        let nullTenant = 'tenant=null';
        if(nowPath.includes(test) && !nowPath.includes(nullTenant)){
            let query_params = nowPath.slice(nowPath.indexOf(test) + test.length);
            if (query_params.includes('&'))
                this.tenant = query_params.split('&')[0];
            else
                this.tenant = query_params;
            this.displayNone = true;
            // call API
            await LoginValidationService.hasLoginValidation(this.tenant)
            .then(response => {
                let isLoginValidation = response.data;
                this.displayLoginVali = isLoginValidation;
            }).catch(() => {
                this.displayLoginVali = false;
            });
        }
        // if url has upchat=Y, show Log in UpChat button, else show Log in UpDay button
        if(nowPath.includes('upchat=Y')) {
            this.displayUpDayLogin = false;
        }
        if(nowPath.includes('upgpt=Y')) {
            this.displayUpGPTLogin = true;
        }
        let user = JSON.parse(window.localStorage.getItem('user'));
        if(user != null)
        {
            this.changePassword = user.change_password
            if(this.changePassword)
            {   
                if(this.displayUpGPTLogin){
                    this.$router.replace('/upgpt/UserSetting/')
                }else{
                    this.$router.replace('/Setting#PasswordAndLogin')
                }
            }
            else if(this.displayUpGPTLogin){
                this.$router.replace("/upgpt/");
            }
            else{
                this.$router.replace(this.nextPath);
            }
        }
    },
  methods: {	
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
        this.iconType ="fa fa-eye"
      } else {
        this.passwordType = "password";
        this.iconType ="fa fa-eye-slash"
      }      
    },
    sendLoginValiCode() {
        if (this.username == null || this.username.trim() == '') {
            this.errorDialog = true;
            this.errorMessage = i18n.t('LOGIN.ERROR_4');
        } else {
            if (this.tenant != '') {
                this.loading = true;
                LoginValidationService.sendLoginValidationCode(this.tenant, this.username)
                .then(() => {
                    this.loading = false;
                    this.messageDialog = true;
                    this.message = i18n.t('LOGIN.SENT_VALI_CODE');
                }).catch(() => {
                    this.loading = false;
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('LOGIN.LOGIN_VAL_ERROR');
                });
            }
        }
    },
    handleLogin(event, isUpDayLogin) {
        event.preventDefault();
        if (this.tenant == ''|this.username == ''|this.password == '') 
        {
            this.errorDialog = true;
            if(this.username == ''|this.password == '')
            {
                this.errorMessage = i18n.t('LOGIN.ERROR');
                this.noneusername = true
            }else
            {
                this.errorMessage = i18n.t('LOGIN.ERROR_2');
            }
        }
        else 
        {
            let tenant = this.tenant.toLowerCase();
            this.loading = true;
            this.login({ username: this.username, password: this.password, tenant: tenant, loginValiCode: this.loginValiCode })
                .then(() => {
                    this.loading = false;
                    this.$emit('loginSuccess');
                    var user = JSON.parse(window.localStorage.getItem('user'));
                    this.changePassword = user.change_password;
                    if (isUpDayLogin) 
                    {   
                        this.forwardToUpDayPage();
                    } 
                    else 
                    {
                        if (user.upchat_sso_link != '')
                        {
                            window.location.href = user.upchat_sso_link;
                        }
                        else
                        {
                            this.forwardToUpDayPage();
                        }
                    }
                })
                .catch((error) => { 
                    this.loading = false;
                    this.errorDialog = true;
                    let error_code = (this.displayLoginVali) ? 'LOGIN.ERROR_3_VALI_CODE' : 'LOGIN.ERROR_3';
                    if (error.response) {
                        let errorMessage = error.response.data.detail;
                        if (errorMessage == 'max_license_number')
                            this.errorMessage = i18n.t('MANAGEUSERS.ERROR');
                        else {
                            this.errorMessage = i18n.t(error_code);
                        }
                    } else {
                        this.errorMessage = i18n.t(error_code);
                    }
                })
                .finally(() => {
                    passData.$emit("status");
                    passData.$emit("upchat",window.localStorage.getItem('isUpchatUser'));
                    if(!this.errorDialog){
                        location.reload();
                    }
                })
        }
    },
    forwardToUpDayPage() {
        if(this.changePassword)
        {
            if(this.displayUpGPTLogin){
                this.$router.replace('/upgpt/UserSetting/')
            }else{
                this.$router.replace('/Setting#PasswordAndLogin')
            }
        }
        else
        {   // check if windows url has 'next'.  
            // If user unauthenticated and click menu bar, it won't call updateAfterLoginNextPath
            // so always get next if exist and use it to replace.
            let nextToPath = this.nextPath;
            if (window.location.href.includes('next=')) {
                let urlNext = decodeURIComponent(window.location.href.split('next=')[1]);
                nextToPath = urlNext;
            }
            this.$router.replace(nextToPath);
        }
    },
    updateAfterLoginNextPath() {	  
      if ('next' in this.$route.query) {
        this.nextPath = this.$route.query.next;		
      }	 
    },
    onEmitSuccessDialog() {
        this.successDialog = false;
    },
    onEmitErrorDialog(val) {
        if(val){
            this.errorDialog = false;
        }
    },	
    onEmitInvitationDialog(val) {
        this.tryFree = val;
    },
    onEmitForgetPasswordDialog(val) {
        this.forgetPassword = val;
    },	
    onEmitForgetPasswordSuccess(val,errorMsg) {
        if(val){
            this.forgetPassword = false;
            this.successDialog = true;
        }else{
            this.errorDialog = true;
            this.errorMessage = errorMsg;
        }
        
    },
    onEmitThankYouDialog(val) {
        this.tryFree = false;
        this.errorDialog = true;
        this.errorMessage = val;
    },
    onEmitMessageDialog(val) { 
        this.messageDialog = false;
        console.log('onEmitMessageDialog: ' + val);
    },    
    ...mapActions([
      'login',
    ]),
    close(ev){        
        if(this.errorDialog && ev.key == "Enter")
        {
            if((this.tenant != ''&& !this.noneusername) | this.nonetenant )
            {
                this.errorDialog = false
                this.nonetenant = false
            }else
            {
                this.nonetenant = true
            }
        }                               
    },
  }
};
</script>

<style scoped src="../../static/loginPage/plugins.css"></style>
<style scoped src="../../static/loginPage/common.css"></style>
<style scoped src="../../static/loginPage/login.css"></style>